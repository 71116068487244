@import "core";
.modal-container.modal-search {	
	.modal-contentwrap {
		padding: calc(10rem + var(--safe-area-insets-top, env(safe-area-inset-top))) 2rem 2rem;
	}

	.search-inputwrap {
		position: relative;
	}

	.search-input {
		background-color: $color-bg;
		border-radius: $radius-small;
		padding: 0 4.5rem 0 3.8rem;
		line-height: 4rem;
		width: 100%;
		display: block;
	}

	.search-icon {
		position: absolute;
		transform: translate3d(0, -50%, 0);
		left: 1.7rem;
		top: 50%;
		font-size: 1.2rem;
		color: $color-primary;
	}

	.search-clearbtn {
		position: absolute;
		right: 2rem;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		opacity: 0;
		font-size: .7rem;
		width: 1.5rem;
		height: 1.5rem;
		background-color: $color-gray;
		color: $color-white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.5rem;

		&.show {
			opacity: 1;
		}
	}
}